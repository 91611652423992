import React from "react"
// import { graphql, Link } from "gatsby"
import Layout from "../components/new-design/Layout"
import { Hero, Cards } from "../components/template"
import Seo from "../components/seo"
import HeadSection from "../components/new-design/HeadSection"
import Expandable from "../components/new-design/Expandable"
import { Link } from "gatsby"
// import { graphql } from "gatsby"

export default function AreaWithCategory({ location, pageContext: { data } }) {
  // console.log(data)
  return (
    <Layout>
      <Seo
        template
        // image={metaImage}
        title={`${data.category.name} in ${data.area.name}`}
        pathname={location.pathname}
        // schemaMarkup={schema}
        // description={property.highlight}
      />
      {/* <code>{JSON.stringify(data)}</code> */}
      {/* <Hero property={data} className="bg-black bg-opacity-50 rounded-xl" /> */}
      <HeadSection title={`${data.category.name} in ${data.area.name}`} />

      <Cards className="py-12" link="/guide/listing/" list={data.listings} />
      {data.listings && <NorImage content={data.listings} />}
    </Layout>
  )
}

const NorImage = ({ content }) => {
  return (
    <div className="py-6">
      <Expandable title={`Other`} reverse>
        <ul className="grid gap-4 md:grid-cols-3 xl:grid-cols-5">
          {content.map((cata, key) => {
            return (
              !cata.image && (
                <li key={key} className="w-full border p-3 rounded-xl">
                  <Link to={`/guide/listing/${cata.slug.current}/`}>
                    <h3 className="text-base">{cata.name}</h3>
                    <p className="text-xs text-stone-500">
                      {cata.shortDescription
                        ? cata.shortDescription
                        : "1 Kilometer Away"}
                    </p>
                  </Link>
                </li>
              )
            )
          })}
        </ul>
      </Expandable>
    </div>
  )
}
